<template>
  <v-app>
    <v-navigation-drawer
      v-if="permission"
      app
      clipped
      v-model="drawer"
      width="200"
    >
      <v-divider></v-divider>

      <v-list dense nav>
        <template v-for="link in links_menu">
          <v-divider v-if="link.divider" :key="link.divider"></v-divider>
          <v-list-item :key="link.title" link :to="{ name: link.link_name }">
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ link.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>ControlTSS - Presentismo</v-toolbar-title>
      <div class="d-flex align-center"></div>
      <v-spacer></v-spacer>
      <v-menu v-if="username" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn depressed color="primary" dark v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>
            <span class="mr-2">{{ username }}</span>
          </v-btn>
        </template>
        <v-list nav dense>
          <v-list-item link to="ChangePassword" key="1">
            <v-list-item-icon>
              <v-icon>mdi-key</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Cambiar Contraseña</v-list-item-title>
          </v-list-item>
          <v-list-item key="2" @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Salir</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>

    <v-footer padless app color="primary">
      <v-col class="primary text-center white--text" cols="12">
        {{ new Date().getFullYear() }} — <strong>TSS Group SRL</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { authComputed } from "./store/helpers.js";
export default {
  name: "App",

  computed: {
    ...authComputed,
  },
  data: () => ({
    links_menu: [
      {
        title: "Inicio",
        link_name: "Inicio",
        icon: "mdi-view-dashboard",
      },
      {
        title: "Novedades",
        link_name: "Novedades",
        icon: "mdi-newspaper",
      },
      {
        title: "Empleados",
        link_name: "Contrataciones",
        icon: "mdi-account-multiple",
      },
      {
        title: "Turnos",
        link_name: "Turnos",
        icon: "mdi-calendar-clock",
      },
      {
        title: "Fichadas",
        link_name: "Turnos",
        icon: "mdi-clock-check-outline",
      },
      {
        title: "Feriados",
        link_name: "Feriados",
        icon: "mdi-calendar-alert",
      },
      {
        title: "Reportes",
        link_name: "Reportes",
        icon: "mdi-chart-bar",
        divider: true,
      },
    ],
    right: null,
    drawer: true,
    group: null,
    username: null,
    permission: false,
  }),
  updated() {
    this.getUserName();
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },

    getUserName() {
      let loggedIn = localStorage.getItem("user");
      if (loggedIn) {
        this.username = JSON.parse(loggedIn).username;
        this.permission = JSON.parse(loggedIn).permission;
      }
    },
  },

  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>
