import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/novedades",
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      hideForAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginPage.vue"),
  },
  {
    path: "/permisosError",
    name: "PermisosError",
    meta: { requiresAuth: true, permError: true },
    component: () =>
      import(
        /* webpackChunkName: "permisosError" */ "../views/PermisosError.vue"
      ),
  },
  {
    path: "/changePassword",
    name: "ChangePassword",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/PasswordChangePage.vue"),
  },
  /* Presentismo */
  {
    path: "/inicio",
    name: "Inicio",
    meta: { requiresAuth: true, requiresPerm: true },
    component: () =>
      import(/* webpackChunkName: "Inicio" */ "../views/Inicio.vue"),
  },
  {
    path: "/novedades",
    name: "Novedades",
    meta: { requiresAuth: true, requiresPerm: true },
    component: () =>
      import(/* webpackChunkName: "Novedades" */ "../views/Novedades.vue"),
  },
  {
    path: "/novedades/alta/:tipoNovedad",
    name: "NovedadAlta",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(/* webpackChunkName: "NovedadAlta" */ "../views/NovedadAlta.vue"),
  },
  {
    path: "/novedad/:id",
    name: "NovedadDetalle",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "NovedadDetalle" */ "../views/NovedadDetalle.vue"
      ),
  },
  {
    path: "/novedad/:id/editar",
    name: "NovedadEdit",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(/* webpackChunkName: "NovedadEdit" */ "../views/NovedadEdit.vue"),
  },
  {
    path: "/novedades/motivos",
    name: "NovedadesMotivos",
    meta: { requiresAuth: true, requiresPerm: true },
    component: () =>
      import(
        /* webpackChunkName: "NovedadesMotivos" */ "../views/NovedadesMotivos.vue"
      ),
  },
  {
    path: "/novedades/motivo/detalle/:id",
    name: "NovedadesMotivoDetalle",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "NovedadesMotivoDetalle" */ "../views/NovedadesMotivoDetalle.vue"
      ),
  },
  {
    path: "/novedades/motivo/:id/editar",
    name: "NovedadesMotivoEdit",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "NovedadesMotivoEdit" */ "../views/NovedadesMotivoEdit.vue"
      ),
  },
  {
    path: "/novedades/motivo/alta",
    name: "NovedadesMotivoAlta",
    meta: { requiresAuth: true },
    // props: true,
    component: () =>
      import(
        /* webpackChunkName: "NovedadesMotivoAlta" */ "../views/NovedadesMotivoAlta.vue"
      ),
  },
  {
    path: "/turnos",
    name: "Turnos",
    meta: { requiresAuth: true, requiresPerm: true },
    component: () =>
      import(/* webpackChunkName: "Turnos" */ "../views/Turnos.vue"),
  },
  {
    path: "/turnos/asignados/:id",
    name: "TurnosAsignados",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "TurnosAsignados" */ "../views/TurnosAsignados.vue"
      ),
  },
  {
    path: "/turnos/alta",
    name: "TurnoAlta",
    meta: { requiresAuth: true },
    // props: true,
    component: () =>
      import(/* webpackChunkName: "TurnoAlta" */ "../views/TurnoAlta.vue"),
  },
  {
    path: "/turnos/asignar",
    name: "TurnoAsignar",
    meta: { requiresAuth: true },
    // props: true,
    component: () =>
      import(
        /* webpackChunkName: "TurnoAsignar" */ "../views/TurnoAsignar.vue"
      ),
  },
  {
    path: "/turnos/asignar/:id/editar",
    name: "TurnoAsignarEdit",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "TurnoAsignarEdit" */ "../views/TurnoAsignarEdit.vue"
      ),
  },
  {
    path: "/turno/:id",
    name: "TurnoDetalle",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "TurnoDetalle" */ "../views/TurnoDetalle.vue"
      ),
  },
  {
    path: "/contrataciones",
    name: "Contrataciones",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "Contrataciones" */ "../views/Contrataciones.vue"
      ),
  },
  {
    path: "/contrataciones/alta",
    name: "ContratacionAlta",
    meta: { requiresAuth: true },
    // props: true,
    component: () =>
      import(
        /* webpackChunkName: "ContratacionesAlta" */ "../views/ContratacionAlta.vue"
      ),
  },
  {
    path: "/contratacion/:id/editar",
    name: "ContratacionEdit",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "ContratacionEdit" */ "../views/ContratacionEdit.vue"
      ),
  },
  {
    path: "/empleado/:id",
    name: "EmpleadoDetalle",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "PersonaDetalle" */ "../views/EmpleadoDetalle.vue"
      ),
  },
  {
    path: "/feriados",
    name: "Feriados",
    meta: { requiresAuth: true, requiresPerm: true },
    component: () =>
      import(/* webpackChunkName: "Feriados" */ "../views/Feriados.vue"),
  },
  {
    path: "/feriado/:id",
    name: "FeriadoDetalle",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "FeriadoDetalle" */ "../views/FeriadoDetalle.vue"
      ),
  },
  {
    path: "/feriado/:id/editar",
    name: "FeriadoEdit",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(/* webpackChunkName: "FeriadoEdit" */ "../views/FeriadoEdit.vue"),
  },
  {
    path: "/feriados/alta",
    name: "FeriadoAlta",
    meta: { requiresAuth: true },
    // props: true,
    component: () =>
      import(/* webpackChunkName: "FeriadoAlta" */ "../views/FeriadoAlta.vue"),
  },
  {
    path: "/reportes",
    name: "Reportes",
    meta: { requiresAuth: true, requiresPerm: true },
    component: () =>
      import(/* webpackChunkName: "Reportes" */ "../views/Reportes.vue"),
  },
  {
    path: "/reportes/turnossemanales",
    name: "ReporteTurnosSemanales",
    meta: { requiresAuth: true, requiresPerm: true },
    component: () =>
      import(
        /* webpackChunkName: "ReporteTurnosSemanales" */ "../views/reports/ReporteTurnosSemanales.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user");
  let permission = false;
  //console.log("loggedIn?: " + loggedIn);
  if (loggedIn) {
    permission = JSON.parse(loggedIn).permission;
  } else permission = false;

  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next("/login");
  } else if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    to.matched.some((record) => !record.meta.permError) &&
    loggedIn &&
    !permission
  ) {
    next("/permisosError");
  } else if (to.matched.some((record) => record.meta.hideForAuth) && loggedIn) {
    next("/");
  } else next();

  // ...

  // router.beforeResolve((to, from, next) => {
  //   // If this isn't an initial page load.
  //   if (to.name) {
  //     // Start the route progress bar.
  //     NProgress.start();
  //   }
  //   next();
  // });

  // router.afterEach((to, from) => {
  //   // Complete the animation of the route progress bar.
  //   NProgress.done();
  // });
});

export default router;
