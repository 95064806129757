import axios from "axios";
// import { mapGetters } from "vuex";
// import moment from "moment";

const loggedIn = localStorage.getItem("user");

const apiClient = axios.create({
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

/*
Re inject the token in axios when 
*/
function initClient(loggedin) {
  if (
    apiClient.defaults.headers.common["Authorization"] &&
    apiClient.defaults.headers.common["Authorization"].length > 8
  )
    return;
  else {
    var userdata = JSON.parse(loggedin);
    apiClient.defaults.headers.common["Authorization"] =
      "Token " + userdata.token;
    if (process.env.NODE_ENV == "development") {
      apiClient.defaults.baseURL = "http://127.0.0.1:8000";
    } else {
      apiClient.defaults.baseURL =
        "https://" + userdata.tenant + ".api.controltss.com.ar";
    }
  }
}

if (loggedIn) initClient(loggedIn);

export default {
  login(user_credentials) {
    if (process.env.NODE_ENV == "development") {
      apiClient.defaults.baseURL = "http://127.0.0.1:8000";
    } else {
      apiClient.defaults.baseURL =
        "https://" + user_credentials.tenant + ".api.controltss.com.ar";
    }
    //delete user_credentials.tenant;
    apiClient.defaults.headers.common["Authorization"] = null;
    return apiClient.post("/auth/", user_credentials);
  },

  setToken(token) {
    apiClient.defaults.headers.common["Authorization"] = "Token " + token;
  },

  changePassword(credentials) {
    return apiClient.patch("/auth/ChangePassword", credentials);
  },

  /* Empleados */

  getEmpleados(texto = null, sector = null, estado = "1", page = "1") {
    let url = "/empleados/empleados?estado=" + estado;
    url += "&page=" + page;
    if (sector) url += "&sector=" + sector;
    if (texto) url += "&buscar=" + texto;
    return apiClient.get(url);
  },

  getEmpleado(id) {
    return apiClient.get("/empleados/empleado/" + id + "/");
  },
  deleteEmpleado(id) {
    return apiClient.delete("/empleados/empleado/" + id + "/");
  },
  putEmpleado(empleado) {
    return apiClient.put("/empleados/empleado/" + empleado.id + "/", empleado);
  },
  postEmpleado(empleado) {
    return apiClient.post("/empleados/empleado/new", empleado);
  },
  getEncoding(id) {
    return apiClient.get("/empleados/dispositivos/codificacion/" + id);
  },
  postEncoding(id) {
    return apiClient.post(
      "/empleados/dispositivos/codificacion/" + id + "/new"
    );
  },
  getTiposTelefono() {
    return apiClient.get("/visitas/personas/telefonos/tipos");
  },
  getSectores(buscar = null, page = "1") {
    let urlstr = "/empleados/sectores?page=" + page;
    if (buscar) urlstr += "&buscar=" + buscar;
    return apiClient.get(urlstr);
  },
  getSector(id) {
    return apiClient.get("/empleados/sector/" + id + "/");
  },
  deleteSector(id) {
    return apiClient.delete("/empleados/sector/" + id + "/");
  },
  postSector(sector) {
    return apiClient.post("/empleados/sector/new", sector);
  },
  putSector(sector) {
    return apiClient.put("/empleados/sector/" + sector.id + "/", sector);
  },
  getLugares(buscar = null, page = "1") {
    let urlstr = "/empleados/lugares?page=" + page;
    if (buscar) urlstr += "&buscar=" + buscar;
    return apiClient.get(urlstr);
  },
  getLugar(id) {
    return apiClient.get("/empleados/lugar/" + id + "/");
  },
  deleteLugar(id) {
    return apiClient.delete("/empleados/lugar/" + id + "/");
  },
  postLugar(lugar) {
    return apiClient.post("/empleados/lugares/new", lugar);
  },
  putLugar(lugar) {
    return apiClient.put("/empleados/lugar/" + lugar.id + "/", lugar);
  },

  getFichadas(desde, hasta, texto = null, page = "1") {
    let urlstr = "/empleados/fichadas?page=" + page;
    if (!desde || !hasta) return apiClient.get(urlstr);

    urlstr += "&desde=" + desde + "&hasta=" + hasta;
    if (texto) urlstr += "&buscar=" + texto;
    return apiClient.get(urlstr);
  },
  getFichada(id) {
    return apiClient.get("/empleados/fichada/" + id + "/");
  },
  getFichadaFoto(id) {
    return apiClient.get("/empleados/fichada/" + id + "/foto/");
  },
  getDispositivos(texto = null, tipo = "0", estado = "1", page = "1") {
    let url = "/empleados/dispositivos?estado=" + estado;
    url += "&page=" + page;
    if (tipo) url += "&tipo=" + tipo;
    if (texto) url += "&buscar=" + texto;
    return apiClient.get(url);
  },
  changeDispositivoPassword(id, credentials) {
    return apiClient.patch(
      "/empleados/dispositivo/" + id + "/changepassword",
      credentials
    );
  },
  getDispositivo(id) {
    return apiClient.get("/empleados/dispositivo/" + id + "/");
  },
  putDispositivo(id, dispositivo) {
    return apiClient.put("/empleados/dispositivo/" + id + "/", dispositivo);
  },
  postDispositivo(dispositivo) {
    return apiClient.post("/empleados/dispositivo/new", dispositivo);
  },
  getDispositivoLugares(id) {
    return apiClient.get("/empleados/dispositivo/" + id + "/lugares");
  },
  getDispositivoEmpleados(id) {
    return apiClient.get("/empleados/dispositivo/" + id + "/empleados");
  },
  deleteDispositivoEmpleado(id) {
    return apiClient.delete("/empleados/dispositivo/empleado/" + id);
  },
  deleteDispositivoLugar(id) {
    return apiClient.delete("/empleados/dispositivo/lugar/" + id);
  },
  postDispositivoLugares(id, dipositivolugares) {
    return apiClient.post(
      "/empleados/dispositivo/" + id + "/addlugares",
      dipositivolugares
    );
  },
  postDispositivoEmpleados(id, dipositivoempleados) {
    return apiClient.post(
      "/empleados/dispositivo/" + id + "/addempleados",
      dipositivoempleados
    );
  },
  postDispositivoClearTokens(id) {
    return apiClient.post("/empleados/dispositivo/" + id + "/cleartokens");
  },

  /* *** */
  /* Visitas */

  getGeneros() {
    return apiClient.get("/visitas/personas/generos");
  },

  getTiposDocumento() {
    return apiClient.get("/visitas/personas/documento/tipos");
  },

  postPersonaFoto(foto) {
    return apiClient.post("/visitas/persona/" + foto.persona + "/foto/", foto);
  },

  putPersonaFoto(foto) {
    return apiClient.put("/visitas/persona/" + foto.persona + "/foto/", foto);
  },

  getPersonaFoto(id) {
    return apiClient.get("/visitas/persona/" + id + "/foto/");
  },

  /* *** */
};
